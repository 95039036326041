@import "globals";

/***Front menu***/
UL.front-menu {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  left: 0;
  top: 0;

  &.front-menu--at {
    LI{
      width: 318px;
      margin-right: 21px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.front-menu--hr {
    LI{
      width: 136px;
      margin-right: 7px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  LI {
    width: 123px;
    height: 232px;
    margin-right: 1px;
    position: relative;
    overflow: hidden;

    A {
      text-decoration: none;
    }

    DIV.tekst {
      position: absolute;
      left: 0px;
      bottom: 0px;
      font-family: $fontFamilyStandard;
      font-size: 13px;
      background-color: #333333;
      line-height: 1.3;
      color: #FFFFFF;
      width: 100%;
      height: 44px;
      text-align: center;
      z-index: 600;
      display: table;
      vertical-align: middle;
    }

    DIV.vatekst {
      display: table-cell;
      vertical-align: middle;
      padding: 0px 10px;
      margin: 0px;
    }

    DIV.color {
      width: 100%;
      height: 100%;
      /*+placement:anchor-bottom-left 0px 0px;*/
      position: absolute;
      left: 0px;
      bottom: 0px;
    }

    DIV.click_layer {
      z-index: 800;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
    }

    DIV.monochrome {
      width: 100%;
      height: 100%;
      z-index: 400;
      /*+placement:anchor-top-left 0px 0px;*/
      position: absolute;
      left: 0px;
      top: 0px;
    }

  }

  $imagePath: url(/static/img/naslovna_menu.jpg);

  LI.resetke DIV.color {
    background-image: $imagePath;
    background-position: 0px -224px;
  }

  LI.perforirana-plocevina DIV.color {
    background-image: $imagePath;
    background-position: -137px -222px;
  }

  LI.ekspandirana-plocevina DIV.color {
    background-image: $imagePath;
    background-position: -274px -222px;
  }

  LI.valovite-mreze DIV.color {
    background-image: $imagePath;
    background-position: -411px -222px;
  }

  LI.varjene-mreze DIV.color {
    background-image: $imagePath;
    background-position: -548px -222px;
  }

  LI.plocevinaste-resetke DIV.color {
    background-image: $imagePath;
    background-position: -685px -222px;
  }

  LI.vodila DIV.color {
    background-image: $imagePath;
    background-position: -822px -222px;
  }
  
  LI.corten DIV.color {
    background-image: $imagePath;
    background-position: -959px -222px;
  }

  LI.resetke DIV.monochrome {
    background-image: $imagePath;
    background-position: 0px -3px;
  }

  LI.perforirana-plocevina DIV.monochrome {
    background-image: $imagePath;
    background-position: -137px -1px;
  }

  LI.ekspandirana-plocevina DIV.monochrome {
    background-image: $imagePath;
    background-position: -274px -1px;
  }

  LI.valovite-mreze DIV.monochrome {
    background-image: $imagePath;
    background-position: -411px -1px;
  }

  LI.varjene-mreze DIV.monochrome {
    background-image: $imagePath;
    background-position: -548px -1px;
  }

  LI.plocevinaste-resetke DIV.monochrome {
    background-image: $imagePath;
    background-position: -685px -1px;
  }

  LI.vodila DIV.monochrome {
    background-image: $imagePath;
    background-position: -822px -1px;
  }
  
  LI.corten DIV.monochrome {
    background-image: $imagePath;
    background-position: -959px -1px;
  }

  /* AT */
  $imagePathAt: url(/static/img/naslovna_menu_at.jpg);

  $colorYOffset: -188px;

  LI.gitterroste {
    DIV.monochrome{
      background-image: $imagePathAt;
    }
    DIV.color{
      background-image: $imagePathAt;
      background-position: 0 $colorYOffset;
    }
  }

  LI.lochbleche {
    $horOffset: 637px;
    DIV.monochrome{
      background-image: $imagePathAt;
      background-position: $horOffset 0;
    }
    DIV.color{
      background-image: $imagePathAt;
      background-position: $horOffset $colorYOffset;
    }
  }

  LI.streckmetall {
    $horOffset: 318px;
    DIV.monochrome{
      background-image: $imagePathAt;
      background-position: $horOffset 0;
    }
    DIV.color{
      background-image: $imagePathAt;
      background-position: $horOffset $colorYOffset;
    }
  }


}

/***Zakaj BENKOtehna***/
.zakaj {
  margin-top: 45px;
  border: 1px solid #E9E9E9;
  width: $pageWidth;
  height: 350px;
  position: relative;
  background-color: #FCFCFC;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  &.warum{
    height: 365px;
  }

  H2 {
    /*+placement:anchor-top-left 32px -16px;*/
    position: absolute;
    left: 32px;
    top: -16px;
    font-family: $fontFamilyStandard;
    padding: 0px 15px;
    margin: 0px;
    z-index: 460;
    font-weight: 500;
  }

  UL {
    line-height: 1.4;
    font-size: 13px;
    list-style: none;
    padding-left: 0px;
    margin-top: 33px;
    width: 106%;
    color: #666666;

    LI {
      width: 215px;
      height: 148px;
      float: left;
      padding-right: 79px;
      margin-left: 50px;
      background-repeat: no-repeat;

      H3 {
        padding-left: 55px;
        font-family: $fontFamilyStandard;
        font-size: 15px;
        line-height: 1.3;
        height: 34px;
        font-weight: 500;
      }

      &.takojsnja-informacija {
        background-image: url(/static/img/ikona_cene.jpg);
        background-position: -2px 4px;

        BODY.hr & H3 {
          padding-left: 47px;
          width: 170px;
        }
      }

      &.narocila-od-1-kosa {
        background-image: url(/static/img/ikona_kos.jpg);
        background-position: -3px 4px;
      }

      &.hitro-narocanje {
        background-image: url(/static/img/ikona_internet_fax.jpg);
        background-position: 1px 6px;

        H3 {
          padding-left: 82px;
        }
      }

      &.obsezna-zaloga {
        background-image: url(/static/img/ikona_zaloga.jpg);
        background-position: -4px 6px;

        H3 {
          padding-left: 67px;
        }
      }

      &.modra-stevilka {
        background-image: url(/static/img/ikona_modra_stevilka.jpg);
        background-position: -3px 3px;

        H3 {
          padding-left: 87px;
        }
      }

      &.hitra-dostava {
        background-image: url(/static/img/ikona_dostava.jpg);
        background-position: -1px 8px;
      }

      /* AT */
      &.sofortiger-preisinfo {
        background-image: url(/static/img/ikona_cene.jpg);
        background-position: -2px 4px;
      }

      &.bestellung-ab-1-stueck {
        background-image: url(/static/img/ikona_kos.jpg);
        background-position: -3px 4px;
      }

      &.schnelle-bestellung {
        background-image: url(/static/img/ikona_internet_fax.jpg);
        background-position: 1px 6px;

        H3 {
          padding-left: 82px;
        }
      }

      &.umfangreiches-lager {
        background-image: url(/static/img/ikona_zaloga.jpg);
        background-position: -4px 6px;

        H3 {
          padding-left: 67px;
        }
      }

      &.individuelle-loesungen {
        background-image: url(/static/img/de_at/ikona_losungen.jpg);
        background-position: -3px 7px;
        H3 {
          padding-left: 71px;
        }
      }

      &.lieferung {
        background-image: url(/static/img/de_at/lieferung_home.png);
        background-position: -4px 8px;
      }

      &.individualne-resitve {
        background-image: url(/static/img/hr/ikona_rjesenja.jpg);
        background-position: -3px 7px;
        H3 {
          padding-left: 71px;
        }
      }

      &.dostava-po-celi-hrvaski {
        background-image: url(/static/img/hr/dostava.jpg);
        background-position: -4px 8px;
      }

    }
  }
}

DIV.cut-border {
  background-color: #F7F7F7;
  width: 200px;
  height: 1px;
  z-index: 450;
  /*+placement:anchor-top-left 31px -1px;*/
  position: absolute;
  left: 31px;
  top: -1px;
}



/***Front footer***/
/*[clearfix]*/
DIV.front_footer {
  margin-top: 27px;
  border: 1px dashed #E6E6E6;
  margin-left: 4px;
  border-width: 1px 0px 1px 0;
  //width: 926px;
  width: $pageWidth;
  box-sizing: border-box;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 32px;
  color: #525252;
  display: inline-block;
}

/*+clearfix {*/
DIV.front_footer:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  font-size: 0;
}

DIV.front_footer_group {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 14px;
}

DIV.front_footer > DIV {
  float: left;
  width: 238px;
  margin: 0px;
  padding: 0px;
  font-size: 11px;
}

DIV.front_footer > DIV > UL {
  list-style: none;
  padding: 0px 0px 0px 0;
  margin: 0px;

  LI {
    margin-bottom: 7px;
    text-decoration: none;
    background-image: url(/static/img/front_bullet.png);
    background-repeat: no-repeat;
    background-position: 3px 5px;
    padding-left: 13px;
    font-size: 11px;

    A {
      text-decoration: none;
      color: #828282;
    }

    &:hover {
      color: #000000;
    }
  }
}


DIV.front_footnote {
  text-align: center;
  font-size: 9px;
  color: #828282;
  padding-top: 9px;
  padding-bottom: 9px;
}

DIV.front_footer H3 {
  margin-top: 0px;
  color: #525252;
  margin-bottom: 9px;
}

DIV.front_footer .naslov {
  margin-bottom: 17px;
  padding-left: 23px;
  background-image: url(/static/img/ikona_naslov.jpg);
  background-repeat: no-repeat;
  background-position: 0px -1px;
}

DIV.front_footer .tel {
  margin-bottom: 17px;
  padding-left: 23px;
  background-image: url(/static/img/ikona_telefon.jpg);
  background-repeat: no-repeat;
  background-position: 0px -1px;
}

DIV.front_footer .email {
  padding-left: 23px;
  margin-bottom: 3px;
  background-image: url(/static/img/ikona_email.jpg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
}

DIV.front_footer .kontakt {
  width: 211px;
  color: #828282;
}
